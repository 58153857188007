import * as validators from "@vuelidate/validators";

import requiredLengthValidator from "./requiredLengthValidator";
import repositoryProviderValidator from "./repositoryProviderValidator";

export const useRequiredValidator = (i18n: any) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(validators.required);
};

export const useRequiredIfValidator = (i18n: any, func: any) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(validators.requiredIf(func));
};

export const useMinValueValidator = (i18n: any, minValue: number) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(
    validators.minValue(
      Math.round(((minValue as number) + Number.EPSILON) * 100) / 100
    )
  );
};

export const useRequiredLengthValidator = (i18n: any, length: number) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(requiredLengthValidator(length));
};

export const useMaxLengthValidator = (i18n: any, length: number) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(validators.maxLength(length));
};

export const useMinLengthValidator = (i18n: any, length: number) => {
  const { createI18nMessage } = validators;
  // It works™
  // @ts-ignore
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(validators.minLength(length));
};
// export const minValue = (minValue: number) =>
//    withI18nMessage(
//       validators.minValue(
//          Math.round(((minValue as number) + Number.EPSILON) * 100) / 100
//       ),
//       {
//          withArguments: true
//       }
//    )

// export const maxValue = (maxValue: number) =>
//    withI18nMessage(
//       validators.maxValue(
//          Math.round(((maxValue as number) + Number.EPSILON) * 100) / 100
//       ),
//       {
//          withArguments: true
//       }
//    )

// export const minLength = (minLength: number) =>
//    withI18nMessage(validators.minLength(minLength), {
//       withArguments: true
//    })

// export const email = withI18nMessage(validators.email)

export const useRepositoryProvidersValidator = (i18n: any, supportedProviders: string[]) => {
  const { createI18nMessage } = validators;
  
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) });

  return withI18nMessage(repositoryProviderValidator(supportedProviders));
}