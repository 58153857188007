import { helpers } from '@vuelidate/validators'

export default function(supportedProviders: string[]) {
    return {
        $validator: function(value: string) {
            const url = new URL(value)

            return !helpers.req(value) || supportedProviders.indexOf(url.hostname) != -1;
        }
    }
}